import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "../../helpers/utility";
import * as actions from "../../store/actions";
import PropTypes from "prop-types";
import { Alert, Button } from "reactstrap";
import Header from "../../components/Navigation/Header/Header";
import Footer from "../../components/Navigation/Footer/Footer";
import classes from "./Layout.module.css";
import StickyAlert from "../../components/UI/StickyAlert/StickyAlert";
import ReleaseBanner from "../../components/UI/ReleaseBanner/ReleaseBanner";
import { ZendeskAPI } from "react-zendesk";
import CertificationQualified from "../../components/CertificationQualified/CertificationQualified";
import ReleaseOverlay from "../../components/UI/ReleaseOverlay/ReleaseOverlay";
import AGBOverlay from "../../components/UI/AGBOverlay/AGBOverlay";

class Layout extends Component {
  state = {
    isReleaseOverlayVisible: false,
 
  };
 

  componentDidMount() {
    this.props.onFetchCurrentVersion();
    this.props.onFetchCurrentAppMessage();
    this.props.onFetchSpecialAppMessage();
  }

  showReleaseOverlayHandler = () => {
    this.setState({ isReleaseOverlayVisible: true });
  };

  hideReleaseOverlayHandler = () => {
    this.setState({ isReleaseOverlayVisible: false });
  };

  hideAGBOverlayHandler = () => {};

  hideReleaseBannerHandler = () => {
    this.props.onHideReleaseBanner();
  };



  render() {
    const {
      isAuthenticated,
      isReceiver,
      hasFreigabeRole,
      username,
      children,
      appVersion,
      portalMessage,
      appLanguage,
      showReleaseBanner,
      istAGBAktuell,
      istDSEAktuell,
     
    } = this.props;

    let isAGBOverlayVisible = false;

    if (!istAGBAktuell || !istDSEAktuell) {
      isAGBOverlayVisible = true;
    }

    if (this.props.containerId !== null) {
      ZendeskAPI("webWidget", "updateSettings", {
        webWidget: {
          contactForm: {
            fields: [
              {
                id: 360018599260,
                prefill: {
                  "*": this.props.containerId,
                },
              },
            ],
          },
        },
      });
    }

    if (this.props.username !== null) {
      ZendeskAPI("webWidget", "prefill", {
        name: {
          value: this.props.username,
          readOnly: false, // optional
        },
        email: {
          value: this.props.email,
          readOnly: false, // optional
        },
      });
    }

    return (
      <>
        {portalMessage && portalMessage.id !== 0 && (
          <StickyAlert
            messageDE={portalMessage.nachricht}
            messageEN={portalMessage.nachricht_EN}
            linkDE={portalMessage.link}
            linkEN={portalMessage.link_EN}
          />
        )}

        <CertificationQualified />
        <Header
          isAuth={isAuthenticated}
          isReceiver={isReceiver}
          hasFreigabeRole={hasFreigabeRole}
          username={username}
        />
        <main className={classes.Content}>
          {appVersion && appVersion !== process.env.REACT_APP_Version && (
            <Alert color="warning" className={classes.Alert}>
              <h4>Update required!</h4>
              <p>
                The current version you are using is outdated. Please refresh
                your browser to get the latest version or click on the button
                <br />
                <br />
                <Button
                  color="warning"
                  onClick={() => window.location.reload()}
                >
                  Update now
                </Button>
              </p>
            </Alert>
          )}
     
          {appVersion && appVersion.id !== 0 && showReleaseBanner && (
            <ReleaseBanner
              appVersion={appVersion}
              appLanguage={appLanguage}
              onShowReleaseOverlay={this.showReleaseOverlayHandler}
              onHideReleaseBanner={this.hideReleaseBannerHandler}
            />
          )}
          
   
       
          <div style={{ height: "20px" }}>&nbsp;</div>
          <ReleaseOverlay
            appLanguage={appLanguage}
            appVersion={appVersion}
            isVisible={this.state.isReleaseOverlayVisible}
            onHideOverlay={this.hideReleaseOverlayHandler}
          ></ReleaseOverlay>
          <AGBOverlay
            appLanguage={appLanguage}
            appVersion={appVersion}
            isVisible={isAGBOverlayVisible}
            onHideOverlay={this.hideAGBOverlayHandler}
          ></AGBOverlay>
          {children}
        </main>
        <Footer />
      </>
    );
  }
}


Layout.propTypes = {
  appLanguage: PropTypes.string,
  isAuthenticated: PropTypes.bool,
  isReceiver: PropTypes.bool,
  hasFreigabeRole: PropTypes.bool,
  username: PropTypes.string,
  email: PropTypes.string,
  appVersion: PropTypes.object,
  portalMessage: PropTypes.object,
  certificationQualified: PropTypes.bool,
  containerId: PropTypes.string,
  showReleaseBanner: PropTypes.bool,
  istAGBAktuell: PropTypes.bool,
  istDSEAktuell: PropTypes.bool,
 
}




const mapStateToProps = (state) => {
  return {
    appLanguage: state.auth.appLanguage,
    isAuthenticated: state.auth.token !== null,
    isReceiver: state.auth.isReceiver,
    hasFreigabeRole: state.auth.hasFreigabeRole,
    username: state.auth.name,
    email: state.auth.email,
    appVersion: state.auth.appVersion,
    portalMessage: state.auth.portalMessage,
    certificationQualified: state.auth.certificationQualified,
    containerId: state.jobBuilder.containerId,
    showReleaseBanner: state.auth.showReleaseBanner,
    istAGBAktuell: state.auth.istAGBAktuell,
    istDSEAktuell: state.auth.istDSEAktuell,
    
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchCurrentVersion: () => dispatch(actions.fetchCurrentVersionFromDB()),
    onFetchCurrentAppMessage: () => dispatch(actions.fetchPortalMessage()),
    onFetchSpecialAppMessage: () =>
      dispatch(actions.fetchPortalMessageSpecial()),
    onHideReleaseBanner: () => dispatch(actions.hideReleaseBanner()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));
