import React, { Component } from "react";
import Translate from "../../../hoc/Locale/Translate";
import classes from "./BrochureDownload.module.css";
import brochurePreview from "../../../assets/images/PublisherParticipate.jpg";
import { connect } from "react-redux";
import * as actions from "../../../store/actions";
import { Button } from "reactstrap";

class BrochureDownload extends Component {
  render() {
    const { appLanguage, onDownloadFile } = this.props;
    return (
      <div>
        <h3 className={classes.Header}>
          <Translate id="Home.Brochure.Header" />
        </h3>
        <div style={{ width: "50%", textAlign: "center", padding: "20px" }}>
          <Translate id="Home.Brochure.Text" />
        </div>
        <div>
          <Button
            className={classes.BrochureButton}
            outline
            color="secondary"
            onClick={() =>
              onDownloadFile(
                appLanguage === "en"
                  ? "assets/pdf/duon-portal_broschure_2024.pdf"
                  : "assets/pdf/duon-portal_broschure_2024.pdf",
                "pdf",
                appLanguage === "en"
                  ? "duon-portal_broschure_2024"
                  : "duon-portal_broschure_2024"
              )
            }
          >
            <Translate id="Home.Brochure.Button" />
          </Button>{" "}
        </div>
        <div>&nbsp;</div>
        <div>
          <img
            src={brochurePreview}
            width="300px"
            style={{ border: "1px solid black", cursor: "pointer" }}
            alt="Doun Portal Broschure"
            onClick={() =>
              onDownloadFile(
                appLanguage === "en"
                  ? "assets/pdf/duon-portal_broschure_2024.pdf"
                  : "assets/pdf/duon-portal_broschure_2024.pdf",
                "pdf",
                appLanguage === "en"
                  ? "duon-portal_broschure_2024"
                  : "duon-portal_broschure_2024"
              )
            }
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    appLanguage: state.auth.appLanguage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDownloadFile: (filePath, fileType, fileName) =>
      dispatch(actions.downloadFile(filePath, fileType, fileName)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BrochureDownload);
