import React from "react";
import classes from "./DeleteUserProfileBanner.module.css";
import PropTypes from "prop-types";
import Translate from "../../../hoc/Locale/Translate";


const DeleteUserProfileBanner = () => {
    return  (
        <>
          <div id="userBanner" className={classes.Main}>
            <div style={{ width: "100%", alignSelf: "center" }}>
              <Translate id="DeleteUserProfileBanner.Message" />
            </div>
          </div>
        </>
      ) 
    } 

    DeleteUserProfileBanner.prototype={
      appLanguage: PropTypes.string.isRequired
    }


export default DeleteUserProfileBanner;