import React from "react";
import Translate from "../../../hoc/Locale/Translate";
import classes from "./PricingDetails.module.css";
import { Container, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins } from "@fortawesome/free-solid-svg-icons";
import { sub } from "date-fns";

const PricingDetails = ({headerText,header,text,subtext}) => {
 
  return (
    
        // <Container>
          <Row className={classes.Row}>
            <Col
              // sm="12"
              // md={{ size: 6, offset: 3 }}
              className={classes.PricingColumn}
            >
              <h4 className={classes.Header}>
                <br />
               
                <FontAwesomeIcon
                  icon={faCoins}
                  size="3x"
                  className={classes.Icons}
                />
                <br />
                <br />
                <p><Translate id={headerText} /></p>
                <Translate id={header}/>
              </h4>
              <div className={classes.PricingText}>
                <Translate id={text} />
              </div>
              <div className={classes.PricingSubText}>
                <Translate id={subtext} />
              </div>
            </Col>
          </Row>
        // </Container>
  );
};

export default PricingDetails;
