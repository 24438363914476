export const ALL_MEDIA_ENDPOINT = "Medien";
export const ALL_MEDIA_AZURE_ENDPOINT = "Medien/Azure";
export const ALL_FREIGABE_MEDIA_ENDPOINT = "/Medien/Freigabe";
export const COPY_JOB_ENDPOINT = "/Auftraege/{id}/ErstelleKopie";
export const COPY_CONTAINER_ENDPOINT = "/Container/{id}/ErstelleKopie";
export const DATES_ENDPOINT = "/Medien/{id}/Erscheinungstermine/Aktuell";
export const DATES_FROM_YEAR_ENDPOINT =
  "/Medien/{id}/Erscheinungstermine/{jahr}";
export const DELETE_CONTAINER_ENDPOINT = "/Container/{id}";
export const DELETE_JOB_ENDPOINT = "/Container/{id}/Auftraege/{auftragsId}";
export const DOWNLOAD_JOB_ENDPOINT = "/Auftraege/{id}/Ticket.zip";
export const DOWNLOAD_REPORT_ENDPOINT = "/Auftraege/{id}/Report/{format}";
export const DOWNLOAD_REPORT_BLOB_DE_ENDPOINT =
  "/Auftraege/{id}/BlobReferenz/LayerReportDE";
export const DOWNLOAD_REPORT_BLOB_EN_ENDPOINT =
  "/Auftraege/{id}/BlobReferenz/LayerReportEN";
export const CONTAINER_ENDPOINT = "/Container/{id}";
export const CONFIRM_USER_REGISTRATION = "/users/{id}/ConfirmEmail?code={code}";
export const ISSUES_ENDPOINT = "/Medien/{id}/Hefte/Aktuell";
export const JOB_PDF_ENDPOINT = "/Auftraege/{id}/DuPdf";
export const JOB_STATUS_ENDPOINT = "/Auftraege/{id}/Status";
export const JOBS_SENDER_ENDPOINT = "/Auftraege/Uebersicht";
export const JOBS_SENDER_SEARCH_ENDPOINT =
  "/Auftraege/Uebersicht?search={keyword}";
export const JOBS_SENDER_BY_DATE_ENDPOINT =
  "/Auftraege/Uebersicht?referenzDatum={datum}";
export const JOBS_RECEIVER_ENDPOINT = "/Auftraege/EmpfangsUebersicht";
export const JOBS_RECEIVER_SEARCH_ENDPOINT =
  "/Auftraege/EmpfangsUebersicht?search={keyword}";
export const JOBS_RECEIVER_BY_DATE_ENDPOINT =
  "/Auftraege/EmpfangsUebersicht?referenzDatum={datum}";
export const JOBS_AZURE_ENDPOINT = "/Auftraege/Azure";
export const JOB_THUMBNAIL_ENDPOINT =
  "/Container/{id}/{auftragsId}/ThumbnailBase64";
export const MEDIA_ENDPOINT = "/Medien/{id}";
export const MEDIA_IMAGE_ENDPOINT = "/Medien/{id}/Bild";
export const NEW_CONTAINER_ENDPOINT = "/Container";
export const NEW_CONTAINER_JOB_ENDPOINT = "/Container/{id}/Auftraege";
export const PREVIEW_IMAGE_ENDPOINT = "/Auftraege/{id}/VorschauBase64";
export const PDF_REPORT_LINK = "/Auftraege/{id}/Report/pdf";
export const PROOF_FILE_ENDPOINT = "/Auftraege/{id}/StartePruefung";
export const REGISTER_USER_ENDPOINT = "/users/RegisterDuonUser";
export const RECEIVE_JOB_ENDPOINT = "/Auftraege/{id}/IstAbgeholt";
export const SAVE_JOB_ENDPOINT = "/Auftraege/{id}/WeitereAngaben";
export const SAVE_JOB_TAGS_ENDPOINT = "/Benutzer/Profil";
export const SEND_JOB_ENDPOINT = "/Container/{id}/StarteVersand";
export const TECHNICAL_INFO_ENDPOINT =
  "/Medien/{id}/Erscheinungstermine/{datum}/Technik?sprache={sprache}";
export const TECHNICAL_INFO_CURRENT_ENDPOINT =
  "/Medien/{id}/TechnikInfos/Aktuell?sprache={sprache}";
export const TECHNICAL_CHECK_ENDPOINT =
  "/Medien/{id}/Erscheinungstermine/{datum}/AnzeigenFormate/{md5hash}/Technik?teilbelegung={istTeilbelegung}&sprache={sprache}";
export const TECHNICAL_CHECK_FREE_FORMAT_ENDPOINT =
  "/Medien/{id}/Erscheinungstermine/{datum}/{platzierung}/Technik?sprache={sprache}";
export const UPDATE_CONTAINER_ENDPOINT = "/Container/{id}";
export const UPDATE_JOB_ENDPOINT = "/Auftraege/{id}";
export const GET_JOB_ENDPOINT = "/Auftraege/{id}";
export const USER_PREFERENCES_ENDPOINT = "/Benutzer/Profil";
export const SEND_PASSWORD_RESET_LINK_REQUEST_ENDPOINT =
  "/users/SendResetPasswortLink";
export const SET_NEW_PASSWORD_WITH_CODE_ENDPOINT = "/users/ResetPasswort";
export const SET_NEW_PASSWORD_ENDPOINT = "/users/PasswortAendern";
export const USER_PROFILE_ENDPOINT = "/users/MeinProfil";

export const DELETE_USER_PROFILE = "/users/DeleteUser"; 

export const USER_NEWSLETTER_ENDPOINT = "/users/MeinNewsletters";
export const SEND_NEW_EMAIL_CONFIRMATION_ENDPOINT =
  "/users/SendEmailConfirmation";
export const UPDATE_USER_PROFILE = "/users/SpeicherMeinProfil";
// export const FIRMEN_INFO_ENDPOINT = '/users/MeineFirma';
export const SEND_COMPANY_INVITATION_TO_USER = "/firmen/ErstelleFirmenTransfer";
export const REGISTER_USER_WITH_COMPANY_ENDPOINT =
  "/users/RegisterDuonUserMitFirma";
export const COMPANY_INFO_FROM_TICKET_ENDPOINT =
  "/firmen/Einladungen/{ticket}/Info";
export const ACCEPT_COMPANY_INVITATION_ENDPOINT =
  "/users/FirmeinladungAnnehmen";
export const COMPANY_USERS_ENDPOINT = "/firmen/benutzer";
export const ASSIGNED_MEDIA_ENDPOINT = "/Benutzer/{id}/ZugewieseneMedien";
export const SAVE_COMPANY_ADMIN_ENDPOINT = "/Benutzer/{id}/FirmenAdmin";
export const SAVE_ASSIGNED_MEDIA_SETTINGS_ENDPOINT =
  "/Benutzer/{id}/ZugewieseneMedien/{mediumId}";
export const ASSIGNED_USERS_FOR_MEDIA_ENDPOINT =
  "/Benutzer/EinemMediumZugewieseneBenutzer/{mediumId}";
export const FREIGABE_DATES_ENDPOINT = "/Medien/{id}/Zertifizierungen";
export const FREIGABE_INFO_ENDPOINT =
  "/Medien/{id}/Zertifizierungen/{datum}?sprache={sprache}";
export const JOB_HISTORY_ENDPOINT =
  "/Auftraege/{id}/Historie?sprache={sprache}";
export const ANONYMOUS_JOB_DOWNLOAD_INFO_ENDPOINT =
  "/Auftraege/Freigaben/{code}";
export const DOWNLOAD_JOB_FILE_ANONYMOUSLY_ENDPOINT =
  "/Auftraege/Freigaben/{code}/{fileType}";
export const DOWNLOAD_JOB_ANONYMOUSLY_THUMBNAIL_IMAGE_ENDPOINT =
  "/Auftraege/Freigaben/{code}/vorschau";
export const SENDER_INFO_ENDPPOINT = "/Container/{id}/VersenderInfos";
export const TECHNICAL_INFO_DOWNLOAD_ENDPOINT =
  "/Medien/{id}/TechnischeInfosAlsPDF/{datum}/{sprache}";
export const DUON_CERTIFIED_ENDPOINT = "/Firmen/ZertifizierteAgenturen";
export const UPLOAD_FILE_SAS = "/Auftraege/{id}/DuPdf/UploadSAS";
export const FETCH_PDF_BLOB_SAS = "/Auftraege/{id}/BlobReferenz/DuPdf";
export const FETCH_CURRENT_APP_VERSION =
  "/Infos/AktuelleKomponentenVersion/webUISticky";
export const PUBLISHERS_ENDPOINT = "/Medien/Verlage";
export const PUBLISHER_DETAIL_ENDPOINT = "/Medien/Verlage/{id}";
export const FETCH_PORTAL_STATUS = "/Infos/AktuellerPortalStatus";
export const FREIGABE_CERTIFY_MEDIUM_ENDPOINT = "/Medien/{id}/Zertifizierungen";
export const FREIGABE_FILE_DOWNLOAD_ENDPOINT =
  "/Medien/{id}/{preisliste}/ZertifizierungsPaketeAlsExcel";
export const FREIGABE_CHANGE_REQUEST_ENDPOINT =
  "/Medien/{id}/AenderungsAnforderungen";
export const FREIGABE_ADD_ATTACHMENT_ENDPOINT =
  "/Medien/{id}/AenderungsAnforderungen/Attachment";
export const FETCH_CURRENT_APP_MESSAGE =
  "/Infos/AktuelleKomponentenNachrichten/webUISticky";
export const SEND_CERTIFICATION_REQUEST =
  "/Benutzer/SendeZertifikatBeantragung";
export const FETCH_CURRENT_APP_MESSAGE_SPECIAL =
  "/Infos/AktuelleKomponentenNachrichten/webUISpecial";
export const JOB_NOTES_ENDPOINT = "/Auftraege/{id}/Anmerkungen";
export const ACCEPT_NEW_AGB_ENDPOINT =
  "/users/SpeicherBenutzerAgbUndDatenschutzZustimmung";
