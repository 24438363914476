import Moment from "moment";
import { deLocale } from "../assets/locale/de";
import { enLocale } from "../assets/locale/en";

import {
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

export const getJobReferenceDate = value => {
  const JOBDATES = {
    AllDates: "0",
    OneYear: "1",
    SixMonths: "2",
    ThreeMonths: "3",
    OneMonth: "4"
  };

  let moment = new Moment();
  switch (value) {
    case JOBDATES.OneYear:
      moment = moment.subtract(1, "years");
      break;
    case JOBDATES.SixMonths:
      moment = moment.subtract(6, "months");
      break;
    case JOBDATES.ThreeMonths:
      moment = moment.subtract(3, "months");
      break;
    case JOBDATES.OneMonth:
      moment = moment.subtract(1, "months");
      break;
    default:
      break;
  }

  return moment.format("YYYY-MM-DD");
};

export const formatDateToGerman = date => {
  return new Moment(date).format("DD.MM.YYYY");
};

export const formatDateTimeToLocale = (dateTime, appLanguage) => {
  dateTime = new Date(dateTime);
  let dateTimeOffset = dateTime.getTimezoneOffset();
  dateTime.setMinutes(dateTime.getMinutes() - dateTimeOffset);
  let moment =
    appLanguage === "en"
      ? new Moment(dateTime).format("DD/MM/YYYY HH:mm")
      : new Moment(dateTime).format("DD.MM.YYYY HH:mm");

  return moment;
};

export const formatDateToLocale = (dateTime, appLanguage) => {
  let moment =
    appLanguage === "en"
      ? new Moment(dateTime).format("DD/MM/YYYY")
      : new Moment(dateTime).format("DD.MM.YYYY");

  return moment;
};

export const translateAsLiteral = (
  appLanguage,
  id,
  count,
  itemName,
  itemId
) => {
  let message =
    appLanguage === "en"
      ? enLocale
          .filter(message => message.id === id)
          .reduce((arr, el) => el, null)
      : deLocale
          .filter(message => message.id === id)
          .reduce((arr, el) => el, null);

  let messageText = null;
  if ((count || count === 0) && message) {
    switch (count) {
      case 0:
        messageText = message.text_0;
        break;
      case 1:
        messageText = message.text_1;
        break;
      default:
        messageText = message.text.replace("%{count}", count);
    }
  } else if (message && message.text) {
    messageText = message.text;

    if (itemName) {
      messageText = messageText.replace("%{name}", itemName);
    }
    if (itemId) {
      messageText = messageText.replace("%{id}", itemId);
    }
  }

  return messageText ? messageText : "";
};

export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties
  };
};

export const emailPattern = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i;

export const checkValidity = (value, rules) => {
  let isValid = true;
  if (!rules) {
    return true;
  }

  if (rules.required) {
    isValid = value.trim() !== "" && isValid;
  }

  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid;
  }

  if (rules.maxLength) {
    isValid = value.length <= rules.maxLength && isValid;
  }

  if (rules.isEmail) {
    isValid = emailPattern.test(value) && isValid;
  }

  if (rules.isNumeric) {
    const pattern = /^\d+$/;
    isValid = pattern.test(value) && isValid;
  }

  return isValid;
};

export const issueStatus = item => {
  if (!item) return null;
  let issueStatus = {
    color: "green",
    text: "offen",
    code: "#12ac4b",
    isAvailable: true
  };

  let today = new Date();
  let deadlineDate = new Date(item.duSchluss);
  let publicationDate = new Date(item.datum);

  //let isToday = (today.toDateString() === deadlineDate.toDateString());
  let isPublicationReached = today - publicationDate > 0;
  let isDeadlineReached = today - deadlineDate > 0;

  if (isPublicationReached) {
    issueStatus.color = "red";
    issueStatus.text = "bereits erschienen";
    issueStatus.code = "rgb(188, 47, 16)";
    issueStatus.isAvailable = false;
  } else if (isDeadlineReached) {
    issueStatus.color = "orange";
    issueStatus.text = "nach Absprache";
    issueStatus.code = "#f57216";
    issueStatus.isAvailable = true;
  }

  return issueStatus;
};

export const isBitIncluded = (decimalValue, bit) => {
  return (decimalValue & bit) === bit ? true : false;
};

// ideally we should store gender in database and translate the title in GUI
export const getAnredeCorrectValue = (appLanguage, anrede) => {
  if (appLanguage === "en") {
    switch (anrede) {
      case "Herr":
        return "Mr.";
      case "Frau":
        return "Mrs.";
        
      default:
        return anrede;
    }
  } else {
    switch (anrede) {
      case "Mr.":
        return "Herr";
      case "Mrs.":
        return "Frau";
      default:
        return anrede;
    }
  }
};

export const sortUpHandler = data => {
  let items = { ...data };
  return Object.keys(items)
    .map(k => items[k])
    .sort(byNameAsc);
};

const byNameAsc = (a, b) => {
  if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
  if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
  return 0;
};

export const getRecommendedPDFType = (pdfFormats, appLanguage) => {
  if (pdfFormats) {
    let recommendedFormat = pdfFormats
      .filter(item => item.istEmpfohlen)
      .reduce((arr, el) => el, null);

    return recommendedFormat
      ? recommendedFormat.bezeichnung
      : translateAsLiteral(appLanguage, "TechnicalInfo.NoInfo");
  }
};

export const addOneMonthToDate = oldDate => {
  const today = new Date();
  return new Date(today.setMonth(today.getMonth() + 1));
};

export const isDateDifferenceMoreThanOneYear = oldDate => {
  const pastDate = new Date(oldDate);
  const today = new Date();

  const oneYearInMs = 365 * 24 * 60 * 60 * 1000;
  const timeDiffInMs = today.getTime() - pastDate.getTime();

  return timeDiffInMs >= oneYearInMs ? false : true;
};

export function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return (
      <Component
        {...props}
        navigation={navigate}
        router={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}

