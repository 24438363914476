import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import PropTypes from "prop-types";
import classes from "./Modal.module.css";

const modal = (props) => {
  return (

    props.fileImageExist ? 
      <Modal isOpen={props.isOpen} toggle={props.onHideModal} className={classes.modalDialog} >
        <ModalHeader
          // className={classes.Header}
          toggle={props.onHideModal}
          close={
            <button className="close" onClick={props.onHideModal} type="button">
              &times;
            </button>
          }
        >
        </ModalHeader>
        <ModalBody style={props.modalBodyStyle}  >{props.modalBody}</ModalBody>
        <ModalFooter>{props.modalFooter}</ModalFooter>
      </Modal>
    :
      <Modal size={props.size}  isOpen={props.isOpen} toggle={props.onHideModal}>
        <ModalHeader
          className={classes.Header}
          toggle={props.onHideModal}
          close={
            <button className="close" onClick={props.onHideModal} type="button">
              &times;
            </button>
          }
        >
          {props.modalHeader}
        </ModalHeader>
        <ModalBody style={props.modalBodyStyle}  >{props.modalBody}</ModalBody>
        <ModalFooter style={{borderTopColor:"white"}}>{props.modalFooter}</ModalFooter>
      </Modal>
  );
};

modal.propTypes = {
  
  isOpen: PropTypes.bool.isRequired,
  onHideModal: PropTypes.func,
  modalHeader: PropTypes.object,
  modalBody: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  modalFooter: PropTypes.object,
  modalBodyStyle: PropTypes.object,
};

export default modal;


